import { isExperimentEnabled } from '@wix/communities-blog-client-common';
import { EXPERIMENT_CATEGORY_MENU_MOBILE_SETTINGS } from '@wix/communities-blog-experiments';
import { getAppSettingsValue } from '../../common/selectors/app-settings-base-selectors';
import { getIsMobileDisplaySettingsEnabled } from '../../common/selectors/mobile-settings-selectors';
import { getIsMobile } from '../../common/store/basic-params/basic-params-selectors';

export const getTextAlignment = (state, layoutParams) =>
  getAppSettingsValue({
    state,
    key: layoutParams.textAlignment.appSettingsPath,
    fallback: layoutParams.textAlignment.defaultAlignment,
  });

export const getUseMobileSettings = (state) => {
  return (
    isExperimentEnabled(state, EXPERIMENT_CATEGORY_MENU_MOBILE_SETTINGS) &&
    getIsMobile(state) &&
    getIsMobileDisplaySettingsEnabled(state)
  );
};
